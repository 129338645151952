* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background-image: url(./assets/background.jpg);
  background-size: cover;
}
